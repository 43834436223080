import {
  codeCodeToCounty,
  wellMapping,
  pipelineMapping
} from "../constants";


export function incrementMessageCount() {
  // Get the current count from localStorage
  let currentCount = localStorage.getItem('messageCount');

  // If there's no existing count, start from 0
  if (!currentCount) {
    currentCount = 0;
  } else {
    currentCount = parseInt(currentCount, 10);
  }

  // Increment the count
  currentCount += 1;

  // Save the updated count back to localStorage
  localStorage.setItem('messageCount', currentCount.toString());

  return currentCount;
}

export function generatePopupContent(feature) {
  if (feature.layer.id === 'final-bottom-file') {  // Assuming this is the well layer id
    return Object.entries(feature.properties).filter(([key]) => {
      // Exclude the W_P attribute
      return key !== 'W_P';
    }).map(([key, value], index) => {
      let displayName;
      let displayValue;

      if (key === 'COUNTY' || key === 'County Code') {
        displayName = 'County';
        displayValue = codeCodeToCounty[value];
      } else {
        displayName = wellMapping[key];
        displayValue = formatWellAttributes(key, value);  // Use the formatWellAttributes function
      }

      if (displayValue === 'N/A') return null;  // Skip over zero values

      // ... (other existing conditions for wells remain unchanged)
      return { displayName, displayValue };
    }).filter(Boolean);
  } else if (feature.layer.id === 'pipelines' || feature.layer.id === 'pipelines 1') {
    return Object.entries(feature.properties).filter(([key]) => {
      // Only include the properties you want for pipelines:
      return ["COUNTY", "PIPE_LENGTH", "COMMODITY1", "DIAMETER", "INTERSTATE", "OPER_NM", "OPERATOR_CONTACT", "STATUS_CD", "SUBSYS_NM", "SYS_NM", "T4PERMIT"].includes(key);
    }).map(([key, value]) => {
      let displayName;
      let displayValue;

      if (key === 'COUNTY' || key === 'County Code') {
        displayName = 'County';
        displayValue = codeCodeToCounty[value];
      } else {
        displayName = pipelineMapping[key];
        displayValue = formatPipelineAttributes(key, value); // Use our new function here.
      }

      return { displayName, displayValue };
    });
  } else if (feature.layer.id === 'final-line-file' || feature.layer.id === 'final-line-file 1') {
    return Object.entries(feature.properties).filter(([key]) => {
      // Only include the properties you want for pipelines:
      return ["LATERAL_LENGTH"].includes(key);
    }).map(([key, value]) => {
      return { displayName: "Lateral Length", displayValue: formatToHumanReadableFeet(value) };
    });
  }
  return [];
};

export function generateTableHeaders(layerIds) {

}

export function convertStringToArray(str) {
  // First, transform the multiplication operation into a single number
  str = str.replace(/(\d+)\s*\*\s*(\d+)/g, (match, p1, p2) => p1 * p2);

  // Then, parse the transformed string into an array
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error("Error parsing the string:", error);
    return null;
  }
}



export function convertFilterNumberToString(filterArray) {
  return filterArray.map((item) => {
    if (Array.isArray(item)) {
      return convertFilterNumberToString(item);
    } else if (typeof item === "number") {
      return item.toString();
    } else {
      return item;
    }
  });
}

export function computeCenterLocation(features) {
  if (!features || features.length === 0) return;

  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLon = Infinity;
  let maxLon = -Infinity;

  features.forEach((feature) => {
    if (feature?.geometry) {
      if (feature.geometry.type === "Point") {
        const [lon, lat] = feature.geometry.coordinates;
        minLat = Math.min(minLat, lat);
        maxLat = Math.max(maxLat, lat);
        minLon = Math.min(minLon, lon);
        maxLon = Math.max(maxLon, lon);
      } else if (feature?.geometry?.type === "MultiLineString") {
        feature.geometry.coordinates.forEach((segment) => {
          segment.forEach((coord) => {
            const [lon, lat] = coord;
            minLat = Math.min(minLat, lat);
            maxLat = Math.max(maxLat, lat);
            minLon = Math.min(minLon, lon);
            maxLon = Math.max(maxLon, lon);
          });
        });
      } else if (feature?.geometry?.type === "MultiPoint") {
        feature.geometry.coordinates.forEach((segment) => {
          segment.forEach((coord) => {
            if (segment.length === 2) {
              let lon = segment[0]
              let lat = segment[1]
              minLat = Math.min(minLat, lat);
              maxLat = Math.max(maxLat, lat);
              minLon = Math.min(minLon, lon);
              maxLon = Math.max(maxLon, lon);
            } else {
              const [lon, lat] = coord;
              minLat = Math.min(minLat, lat);
              maxLat = Math.max(maxLat, lat);
              minLon = Math.min(minLon, lon);
              maxLon = Math.max(maxLon, lon);
            }
          });
        });
      }


      // Add more geometry types if necessary
    }
  });

  return {
    latitude: (minLat + maxLat) / 2,
    longitude: (minLon + maxLon) / 2,
  };
}


const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export function formatToHumanReadableFeet(value) {
  const formattedNumber = new Intl.NumberFormat().format(value);
  return `${formattedNumber} feet`;
}

export function formatWellAttributes(key, value) {
  key = key.toUpperCase();  // Convert the key to uppercase for matching

  // Handle missing values or '0'
  if (value === undefined || value === null || value === 0 || value === '0' || value === '00000000') {
    return 'N/A'; // Default placeholder for missing/invalid values
  }

  // If it's not a string, try converting it to string for further processing
  if (typeof value !== 'string') {
    value = value.toString();
  }

  if (key === "LEASE_ID" && value === '000000') {
    return "No lease number available";
  }

  switch (key) {
    case "LATERAL_LENGTH":
      return formatNumberWithCommas(value) + " ft";
    case "TOTAL_DEPT":  // Updated the key here
      return formatNumberWithCommas(value) + " ft";
    case "LSE_GAS_PROD_VOL":
    case "LSE_CSGD_PROD_VOL":
      return formatNumberWithCommas(value) + " mcf/month";
    case "LSE_OIL_PROD_VOL":
    case "LSE_COND_PROD_VOL":
      return formatNumberWithCommas(value) + " bbl/month";
    case "FLARE_VOL":
      return formatNumberWithCommas(value) + " mcf/month";
    case "SYMNUM":
      const symbolMapping = {
        '2': 'Permitted Location',
        '3': 'Dry Hole',
        '4': 'Oil Well',
        '5': 'Gas Well',
        '6': 'Oil/Gas Well',
        '7': 'Plugged Oil Well',
        '8': 'Plugged Gas Well',
        '9': 'Canceled Location',
        '10': 'Plugged Oil/Gas Well',
        '11': 'Injection/Disposal Well',
        '19': 'Shut-In Well (Oil)',
        '20': 'Shut-In Well (Gas)',
      };
      return symbolMapping[value] || 'Other';
    case "LAST_PROD":
    case "LAST_FLARED":
      return months[parseInt(value.slice(4, 6)) - 1] + " " + value.slice(0, 4);
    case "COMPLETION":
    case "PLUG_DATE":
      return months[parseInt(value.slice(4, 6)) - 1] + " " + value.slice(6, 8) + ", " + value.slice(0, 4);
    default:
      return value; // Return original value if no transformation is defined
  }
}

function formatWellAttributesForTable(key, value) {
  key = key.toUpperCase();  // Convert the key to uppercase for matching

  // Handle missing values or '0'
  if (value === undefined || value === null || value === 0 || value === '0' || value === '00000000') {
    return 'N/A'; // Default placeholder for missing/invalid values
  }

  // If it's not a string, try converting it to string for further processing
  if (typeof value !== 'string') {
    value = value.toString();
  }

  if (key === "LEASE_ID" && value === '000000') {
    return "No lease number available";
  }

  switch (key) {
    case "LATERAL_LENGTH":
    case "TOTAL_DEPT":
      return value; // Omit "ft"
    case "LSE_GAS_PROD_VOL":
    case "LSE_CSGD_PROD_VOL":
    case "LSE_OIL_PROD_VOL":
    case "LSE_COND_PROD_VOL":
    case "FLARE_VOL":
      return value; // Omit "mcf/month" or "bbl/month"
    case "SYMNUM":
      const symbolMapping = {
        '2': 'Permitted Location',
        '3': 'Dry Hole',
        '4': 'Oil Well',
        '5': 'Gas Well',
        '6': 'Oil/Gas Well',
        '7': 'Plugged Oil Well',
        '8': 'Plugged Gas Well',
        '9': 'Canceled Location',
        '10': 'Plugged Oil/Gas Well',
        '11': 'Injection/Disposal Well',
        '19': 'Shut-In Well (Oil)',
        '20': 'Shut-In Well (Gas)',
      };
      return symbolMapping[value] || 'Other';
    case "LAST_PROD":
    case "LAST_FLARED":
      return months[parseInt(value.slice(4, 6)) - 1] + " " + value.slice(0, 4);
    case "COMPLETION":
    case "PLUG_DATE":
      return months[parseInt(value.slice(4, 6)) - 1] + " " + value.slice(6, 8) + ", " + value.slice(0, 4);
    default:
      return value; // Return original value if no transformation is defined
  }
}

export {
  // ... other exported functions
  formatWellAttributesForTable
};

export function isFalsyOrZeroes(str) {
  return !str || /^0+$/.test(str);
}

export function formatPipelineAttributes(key, value) {
  switch (key) {
    case 'PIPE_LENGTH':
      return `${parseFloat(value).toFixed(1)} miles`;
    case 'STATUS_CD':
      return value === 'I' ? 'In Service' : value === 'B' ? 'Abandoned' : value;
    case 'COMMODITY1':
      const commodityMapping = {
        'AA': 'Anhydrous Ammonia',
        'CO2': 'Carbon Dioxide',
        'CRO': 'Crude Oil',
        'CRL': 'Crude Oil',
        'CFL': 'Crude Oil',
        'CRA': 'Crude Oil',
        'HVL': 'Highly Volatile Liquid',
        'PRD': 'Refined Liquid Product',
        'NGT': 'Natural Gas',
        'NGG': 'Natural Gas',
        'NFG': 'Natural Gas',
        'NGZ': 'Natural Gas',
        'OGT': 'Other Gas'
      };
      return commodityMapping[value] || value; // Return the original value if not found in the mapping.
    case 'DIAMETER':
      return `${value} in`; // Assuming diameter is in inches.
    case 'INTERSTATE':
      return value === 'N' ? 'No' : value === 'Y' ? 'Yes' : value; // Convert "N" to "No" and "Y" to "Yes".
    default:
      return value;
  }
}

export function formatPipelineAttributesForTable(key, value) {
  switch (key) {
    case 'PIPE_LENGTH':
      return parseFloat(value).toFixed(1); // Removed 'miles'
    case 'STATUS_CD':
      return value === 'I' ? 'In Service' : value === 'B' ? 'Abandoned' : value;
    case 'COMMODITY1':
      const commodityMapping = {
        'AA': 'Anhydrous Ammonia',
        'CO2': 'Carbon Dioxide',
        'CRO': 'Crude Oil',
        'CRL': 'Crude Oil',
        'CFL': 'Crude Oil',
        'CRA': 'Crude Oil',
        'HVL': 'Highly Volatile Liquid',
        'PRD': 'Refined Liquid Product',
        'NGT': 'Natural Gas',
        'NGG': 'Natural Gas',
        'NFG': 'Natural Gas',
        'NGZ': 'Natural Gas',
        'OGT': 'Other Gas'
      };
      return commodityMapping[value] || value; // Return the original value if not found in the mapping.
    case 'DIAMETER':
      return value; // Removed 'in'
    case 'INTERSTATE':
      return value === 'N' ? 'No' : value === 'Y' ? 'Yes' : value; // Convert "N" to "No" and "Y" to "Yes".
    default:
      return value;
  }
}

function formatNumberWithCommas(value) {
  // If the value is a string, try converting it to a number
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  // If value is a valid number, format it; otherwise return the original value
  return !isNaN(value) ? value.toLocaleString() : value;
}

export function getMonthName(monthNumber) {
  // Create a date object with the desired month (monthNumber - 1 because months are 0-indexed in JavaScript)
  const date = new Date(2020, monthNumber - 1, 1);

  // Use toLocaleString to get the month name
  return date.toLocaleString('default', { month: 'long' });
}

export function generateUrl(leaseName, leaseNumber, wellType, district, startMonth, startYear, endMonth, endYear, symNumValue) {
  const baseUrl = "https://webapps2.rrc.texas.gov//EWA/specificLeaseQueryAction.do";

  // Construct the pdqSearchArgs.paramValue parameter
  let paramValue = `|2=${startMonth}|3=${startYear}|4=${endMonth}|5=${endYear}|103=${leaseNumber}|6=${wellType}|102=${district}|8=specificlease|204=district|9=dispdetails|10=${symNumValue}`;

  // URL encode the paramValue for safe use in the URL
  let encodedParamValue = encodeURIComponent(paramValue);

  // Construct the full URL
  let fullUrl = `${baseUrl}?tab=init&viewType=prodAndTotalDisp&methodToCall=fromGisViewer&pdqSearchArgs.paramValue=${encodedParamValue}`;

  return fullUrl;
}

export function downloadCSV(mapData) {
  const wells = ["final-bottom-file", "final-line-file", "final-surface-file"];
  const hasWell = mapData.some(item => wells.includes(item.layer.id));

  // If well data exists, filter out the pipeline data
  if (hasWell) {
    mapData = mapData.filter(item => wells.includes(item.layer.id));
  }

  let csv = convertJSONtoCSV(mapData);
  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  let url = URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'data.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function convertJSONtoCSV(json) {
  let csv = '';

  // Create headers without "lat" and "long"
  let headers = Object.keys(json[0].properties).join(",");

  // Append "lat" and "long" to the end of the headers
  headers += ',lat,long';
  csv += headers + '\n';

  for (let row of json) {
    let line = '';

    for (let field of headers.split(",").slice(0, -2)) { // Process all fields excluding lat,long
      if (row.properties.hasOwnProperty(field)) {
        line += '"' + (row.properties[field] || '') + '",';
      } else {
        line += ',';
      }
    }

    // Add "lat" and "long" to the line at the end
    if (row.geometry && row.geometry.coordinates && row.geometry.coordinates.length === 2) {
      line += row.geometry.coordinates[1] + ',' + row.geometry.coordinates[0]; // lat, long
    } else {
      line += ','; // empty lat
      line += ','; // empty long
    }

    csv += line + '\n';
  }
  return csv;
}

export function computeAverageLocation(features) {
  let totalLat = 0;
  let totalLon = 0;
  let count = 0;

  if (features) {
    // Iterate over all the features in the GeoJSON data
    features.forEach((feature) => {
      if (feature?.geometry) {
        if (feature.geometry.type === "Point") {
          totalLat += feature?.geometry?.coordinates[1];
          totalLon += feature?.geometry?.coordinates[0];
          count++;
        } else if (feature?.geometry?.type === "MultiLineString") {
          feature?.geometry?.coordinates.forEach((segment) => {
            segment.forEach((coord) => {
              totalLat += coord[1];
              totalLon += coord[0];
              count++;
            });
          });
        } // Add more geometry types if necessary
      }
    });

    return {
      latitude: totalLat / count,
      longitude: totalLon / count,
    };
  }
}

export function toGeoJSON(response) {
  // Create an initial GeoJSON structure
  if (!response) {
    console.log('No GeoJSON data')
    return null;
  }

  const geojson = {
    type: "FeatureCollection",
    features: [],
  };

  // Loop through each item in the data array
  response.forEach((item) => {
    // Extract the locations and convert them from string to JSON
    const bottom_location = JSON.parse(item.bottom_location);
    const line_location = JSON.parse(item.line_location);
    const surface_location = item.surface_location
      ? JSON.parse(item.surface_location)
      : null;

    // Create a feature for each location and add them to the GeoJSON structure
    geojson.features.push({
      type: "Feature",
      geometry: bottom_location,
      properties: {
        api: item.api,
        classification: item.classification,
        depth: item.depth,
        field_name: item.field_name,
        lease_name: item.lease_name,
        oil_gas_co: item.oil_gas_co,
        on_off_sch: item.on_off_sch,
        operator: item.operator,
        phone: item.phone,
        type: "bottom_location",
        symnum: item.symnum,
      },
    });

    geojson.features.push({
      type: "Feature",
      geometry: line_location,
      properties: {
        api: item.api,
        classification: item.classification,
        depth: item.depth,
        field_name: item.field_name,
        lease_name: item.lease_name,
        oil_gas_co: item.oil_gas_co,
        on_off_sch: item.on_off_sch,
        operator: item.operator,
        phone: item.phone,
        type: "line_location",
        symnum: item.symnum,
      },
    });

    if (surface_location) {
      geojson.features.push({
        type: "Feature",
        geometry: surface_location,
        properties: {
          api: item.api,
          classification: item.classification,
          depth: item.depth,
          field_name: item.field_name,
          lease_name: item.lease_name,
          oil_gas_co: item.oil_gas_co,
          on_off_sch: item.on_off_sch,
          operator: item.operator,
          phone: item.phone,
          type: "surface_location",
          symnum: item.symnum,
        },
      });
    }
  });

  return geojson;
}

export function getCurrentMonthAndYear() {
  const currentDate = new Date();

  const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1 to get the correct month number
  const leadingZeroMonth = '0' + month
  const year = currentDate.getFullYear();

  return {
    month: leadingZeroMonth, // This will be a number between 1 (January) and 12 (December)
    year  // This will be the four-digit year, e.g., 2023
  };
}

export function formatDateToHumanReadable(input) {
  // Check if the input is valid
  if (input.length !== 8 || isNaN(input)) {
    return "Invalid date format";
  }

  // Extract year, month, and day
  const year = input.substring(0, 4);
  const month = input.substring(4, 6);
  const day = input.substring(6, 8);

  // Convert to a JavaScript Date object
  const date = new Date(year, month - 1, day);

  // Format the date using toLocaleDateString
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}