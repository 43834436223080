import React, { useEffect, useState } from "react";
import { regex } from "../utils/regex";
import { Form, Modal, Button } from "react-bootstrap";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import uploadprofile from "../../src/images/upload-profile.png";
import { db } from "../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { getAuth, updatePassword } from "firebase/auth";

const EditProfile = (props) => {
  const userData = props.userData;
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [origanization, setOrganiztion] = useState("");

  useEffect(() => {
    if (userData?.displayName) {
      setFirstName(userData?.displayName.split(" ")[0]);
      setLastName(userData?.displayName.split(" ")[1]);
      setOrganiztion(userData?.origanization ? userData?.origanization : "");
      setEmail(userData?.email ? userData?.email : "");
    }
  }, [userData]);

  const handleSubmit = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!firstName) {
      toast("First Name Required");
    } else if (!lastName) {
      toast("Last Name Required");
    } else if (!email) {
      toast("Email Required");
    } else if (!regex.validateEmail(email)) {
      toast("Invalid Email");
    } else if (!password) {
      toast("Passsword Required");
    } else {
      if (password.length > 0) {
        updatePassword(user, password)
          .then((response) => {
            let displayName = firstName + " " + lastName;
            const userRef = doc(db, "Users", userData.uid.toString());
            setDoc(
              userRef,
              { capital: true, displayName, email, origanization },
              { merge: true }
            );
            toast("Profile Updated");
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          })
          .catch((error) => {
            toast("Profile Updating Failed");
          });
      } else {
        let displayName = firstName + " " + lastName;
        const userRef = doc(db, "Users", userData.uid.toString());
        setDoc(
          userRef,
          { capital: true, displayName, email, origanization },
          { merge: true }
        );
        toast("Profile Updated");
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    }
  };

  return (
    <Modal
      className="profile-modal close-btn"
      show={props.editShow}
      onHide={props.close}
    >
      <Modal.Header>
        <h3>Edit Profile</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="profile-main">
          <div className="prof-detail">
            <div className="prof-img">
              {/* <img src={uploadprofile} alt="icon img" /> */}
              <div className="profile-circle">
                <p>
                  {userData?.displayName
                    ? userData?.displayName.split("")[0]
                    : ""}
                </p>
                <p>
                  {userData?.displayName
                    ? userData?.displayName.split(" ")[1][0]
                    : ""}
                </p>
              </div>
              <div className="prof-email">
                <div className="uploadfile">
                  <BsFillCloudUploadFill />
                  Upload Pictures
                  <input type="file" />
                </div>
                <button>Remove Pictures</button>
              </div>
            </div>
          </div>

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Organization (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Organization"
                value={origanization}
                onChange={(e) => setOrganiztion(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="************"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button className="clr-btn" onClick={handleSubmit}>
              Submit
            </Button>
            <div className="google-login"></div>
          </Form>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
};

export default EditProfile;
