import { Container, Form, Button } from 'react-bootstrap';
import React, { useEffect } from 'react';
import loginicon from '../../../src/images/login-img.png';
import axios from 'axios';

const endpoint = "https://us-central1-rrc-mapping.cloudfunctions.net/send-email";

function Datarequest() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const [hasSentEmail, setHasSentEmail] = React.useState(false); // state variable to track whether email has been sent or not

  const handleSubmit = async (event, t) => {
    console.log('handleSubmit', event)

    console.log('handleSubmit', event.target.email.value)
    event.preventDefault();

    const email = event.target.email.value; // get email value from form
    const exampleQuery = event.target.exampleQuery.value; // get exampleQuery value from form
    const exampleOutput = event.target.exampleOutput.value; // get exampleQuery value from form
    const notes = event.target.notes.value; // get exampleQuery value from form

    console.log(email, exampleOutput, exampleQuery, notes)
    try {
      await axios.post(endpoint, { email, exampleQuery, exampleOutput, notes }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setHasSentEmail(true)
      console.log("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  }


  return (
    <div className='about-sec'>
      <Container fluid>
        <div className='card-bg card-blue'>
          <div className='formblock'>
            <div style={{ textAlign: 'center' }}>
              <img src={loginicon} alt="icon img" />
              <h2>Data Request Form</h2>
              <p>Fill out a data request form for any additional data requests.</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ paddingLeft: '20px' }}>Sample Query</Form.Label>
                <Form.Control name="exampleQuery" type="text" placeholder="e.g, ‘Show me all of the wells in Anderson County" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ paddingLeft: '20px' }}>Expected Output</Form.Label>
                <Form.Control name="exampleOutput" type="text" placeholder="A CSV file with well details, etc." />
              </Form.Group>
              {/* <Form.Select className="mb-3" aria-label="Default select example">
                <option>.csv</option>
                <option value="1">.csv</option>
                <option value="2">.xlsx</option>
                <option value="3">.json</option>
                <option value="3">.text</option>
              </Form.Select> */}
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ paddingLeft: '20px' }}>Notes</Form.Label>
                <Form.Control name="notes" type="text" placeholder="Any additional notes." />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ paddingLeft: '20px' }}>Email</Form.Label>
                <Form.Control name="email" type="email" placeholder="youremail@gmail.com" />
              </Form.Group>
              {hasSentEmail ?

                <div className="alert alert-success" role="alert">
                  Your request has successfully been submitted!
                </div>
                : <Button type="submit" className='clr-btn'>Submit</Button>}

            </Form>
          </div>
        </div>

      </Container>
    </div>

  );

}


export default Datarequest;
