import { useState } from "react";
import {
    sendPasswordResetEmail
} from 'firebase/auth';
import { authentication } from './../../config/firebase';

export const passwordReset = async (email) => {
    return await sendPasswordResetEmail(authentication, email)
}

function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [emailMessage, setEmailMessage] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await passwordReset(email);
            setEmailMessage(true);
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                alert('User not found, try again!')
                setEmail('')
            }
        }
    };

    return (
        <div>
            {
                emailMessage ?
                    <h3 style={{ color: "white" }}>The password reset email has been sent. Please check your inbox!</h3> :
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            name="email"
                            placeholder="name@email.com"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <div>
                            <button type='submit'>Reset Your Password</button>
                        </div>
                    </form>
            }
        </div>
    )
}

export default ForgotPassword