import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import Dropdown from "react-dropdown";
import doc from "../../images/doc.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import crossIcon from "../../images/cross.png";
import download from "../../images/download.png";
import { Container, Row, Col, Button } from "react-bootstrap";
import { YearPicker, MonthPicker } from "react-dropdown-date";
import whiteRightArrow from "../../images/whiteRightArrow.png";
import gradientLeftArrow from "../../images/gradientLeftArrow.png";
import { useLocation } from 'react-router-dom';
import { getCurrentMonthAndYear, getMonthName } from '../../utils';
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Label } from "recharts";
import { ResponsiveContainer } from 'recharts';



const symNumToOilGasCode = {
    '2': null,
    '3': null,
    '4': 'O',
    '5': 'G',
    '6': 'O',
    '7': 'O',
    '8': 'G',
    '9': null,
    '10': 'O',
    '11': null,
    '19': 'O',
    '20': 'G',
};

const convertToChartData = (apiData) => {
    // Extract the relevant fields and compute the sums for Y-axis
    const convertedData = apiData.map(item => {
        const month = item.cycle_month - 1;
        const year = item.cycle_year;
        const oilAndCondensate = parseInt(item.cnty_lse_oil_prod_vol) + parseInt(item.cnty_lse_cond_prod_vol);
        const gasAndCasingheadGas = parseInt(item.cnty_lse_csgd_prod_vol) + parseInt(item.cnty_lse_gas_prod_vol);
        return {
            name: `${month}/${year}`,  // X-axis data
            "Oil and Condensate": oilAndCondensate,  // Y-axis data for the first line
            "Gas and Casinghead Gas": gasAndCasingheadGas  // Y-axis data for the second line
        };
    });

    // Sort the data by time (from earliest to latest)
    convertedData.sort((a, b) => {
        const [monthA, yearA] = a.name.split('/').map(Number);
        const [monthB, yearB] = b.name.split('/').map(Number);

        if (yearA !== yearB) return yearA - yearB;
        return monthA - monthB;
    });

    return convertedData;
};

const convertToTableData = (apiResponse) => {
    return apiResponse.map(item => {
        const disposition = sumDispositionFields(apiResponse[0]);
        console.log(' item.cnty_lse_oil_prod_vol', item.cnty_lse_oil_prod_vol)
        const oilAndCondensate = parseInt(item.cnty_lse_oil_prod_vol) + parseInt(item.cnty_lse_cond_prod_vol);
        const gasAndCasingheadGas = parseInt(item.cnty_lse_csgd_prod_vol) + parseInt(item.cnty_lse_gas_prod_vol);
        return {
            Date: `${getMonthName2(item.cycle_month - 1)} ${item.cycle_year}`,
            FieldName: item.field_name,
            OperatorName: item.operator_name,
            OperatorNo: item.operator_no,
            CountyName: item.county_name,
            LeaseName: item.lease_name,
            OilProduction: oilAndCondensate.toLocaleString(),
            GasProduction: gasAndCasingheadGas.toLocaleString(),
            Disposition: disposition,
            FieldNo: item.field_no,
        };
    });
}

function sumDispositionFields(data) {
    let disposition = 0;
    for (const key in data) {
        if (/lease_[a-z]+_dispcd\d{2}_vol/.test(key)) {
            disposition += Number(data[key]);
        }
    }
    return disposition;
}



// Helper function to convert month number to its name
const getMonthName2 = (monthNumber) => {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthNumber]; // monthNumber is 1-indexed
}

function ProductionDataChart(props) {
    const location = useLocation();
    const data = location.state
    const [startYear, setStartYear] = useState(null);
    const [startMonth, setStartMonth] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([])
    const [pageSize, setPageSize] = useState("10");
    const options = ["10", "20", "30", "40", "50", "60", "70"];
    const defaultOption = options[0];
    const [currentPage, setCurrentPage] = useState("1");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [leaseName, setLeaseName] = useState(data && data["LEASE_NAME"] || "");
    const [leaseNumber, setLeaseNumber] = useState(data && data["LEASE_ID"] || "");
    const [operatorName, setOperatorName] = useState(data && data["OPERATOR"] || "");
    const [wellType, setWellType] = useState(data && data["W_P"] === "well" ? "well" : "pipeline");
    const [oilGasCode, setOilGasCode] = useState(data && symNumToOilGasCode[data['SYMNUM']]);
    const [countyCode, setCountyCode] = useState(data && data["COUNTY"] || "")

    const { month: currentMonth, year: currentYear } = getCurrentMonthAndYear();
    console.log('currentMonth', currentMonth)
    const [dateRange, setDateRange] = useState({
        startYear: 2022,
        startMonth: 6,
        endYear: currentYear,
        endMonth: currentMonth[1], // Assuming this is the numeric representation of the month
    });
    const pages = [
        { page: "1", active: currentPage === "1" },
        { page: "2", active: currentPage === "2" },
        { page: "3", active: currentPage === "3" },
        { page: "4", active: currentPage === "4" },
        { page: "5", active: currentPage === "5" },
        { page: "6", active: currentPage === "6" },
        { page: "7", active: currentPage === "7" },
        { page: "8", active: currentPage === "8" },
        { page: "9", active: currentPage === "9" },
    ];
    const [isLoading, setIsLoading] = useState(false); // State for loading

    useEffect(() => {
        console.log('DateRange', dateRange)
        const fetchData = async () => {
            console.log('data', data)
            console.log('Initial query: leaseName', leaseName)
            console.log('Initial query: leaseNumber', leaseNumber)
            console.log('Initial query: wellType', wellType)
            console.log('Initial query: startMonth startYear', startMonth, startYear)
            console.log('Initial query: endMonth startYear', endMonth, endYear)
            console.log('Initial query: countyCode ', countyCode)

            setIsLoading(true);
            console.log('oilGasCode', oilGasCode)
            try {
                const response = await axios.post(
                    "https://us-central1-rrc-mapping.cloudfunctions.net/query-rds",
                    { leaseName, leaseNumber, wellType, startMonth, startYear, endMonth, endYear, oilGasCode, countyCode },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                console.log('First API response item:', response.data[0]);
                console.log('Last API response item:', response.data[response.data.length - 1]);
                const fetchedData = response.data;
                console.log("fetchedData", response.data)

                if (!fetchedData.length) {
                    console.log('No data returned.')
                    setIsLoading(false);
                    return;
                }

                const startingPosition = response.data.length - 1

                let newEndYear = response.data[0].cycle_year
                let newStartMonth = (+response.data[0].cycle_month).toString()
                let newStartYear = response.data[startingPosition].cycle_year
                let newEndMonth = (+response.data[startingPosition].cycle_month).toString()

                if (!startMonth) {
                    setEndMonth(newEndMonth)
                }

                if (!startYear) {
                    setEndYear(newEndYear)
                }

                if (!startMonth) {
                    setStartMonth(newStartMonth)
                }

                if (!startYear) {
                    setStartYear(newStartYear)
                }




                console.log('startMonth, startYear, endMonth, endYear ', newStartMonth, newStartYear, newEndMonth, newEndYear)
                updateDateRange({ startMonth: newStartMonth, startYear: newStartYear, endMonth: newEndMonth, endYear: newEndYear })
                const tableData = convertToTableData(fetchedData)
                const chartData = convertToChartData(fetchedData);
                console.log('chartData', chartData)
                console.log('tableData', tableData)
                setIsLoading(false);
                setTableData(tableData)
                setChartData(chartData);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const updateDateRange = (updates) => {
        console.log('updateDateRange', updates)
        setDateRange((prevRange) => ({
            ...prevRange,
            ...updates,
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNextPage = () => {
        const maxPage = Math.ceil(tableData.length / itemsPerPage);
        if (currentPage < maxPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const generateCSV = (data) => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map(row => {
            return Object.values(row).map(value => `"${value}"`).join(",");
        });
        return [header, ...rows].join("\n");
    };

    const downloadCSV = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);


    const handleSubmit = async () => {
        console.log('Executing query', leaseName, leaseNumber, wellType, startMonth, startYear, endMonth, endYear, oilGasCode, countyCode)
        const adjustedStartMonth = parseInt(startMonth) + 1; // or - 1, depending on your need
        const adjustedEndMonth = parseInt(endMonth) + 1; // or - 1, depending on your need
        console.log("County code before API call in handleSubmit:", countyCode);
        try {
            const response = await axios.post(
                "https://us-central1-rrc-mapping.cloudfunctions.net/query-rds",
                { leaseName, leaseNumber, wellType, startMonth: adjustedStartMonth, startYear, endMonth: adjustedEndMonth, endYear, oilGasCode, countyCode },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("API response in handleSubmit:", response);

            console.log("response", response)
            console.log('Results from db', response.data)
            const tableData = convertToTableData(response.data)
            console.log('tableData', tableData)
            setTableData(tableData)
            const chartData = convertToChartData(response.data);
            console.log('chartData', chartData)
            console.log('response.data', response.data);
            console.log('First chartData item:', chartData[0]);
            console.log('Last chartData item:', chartData[chartData.length - 1]);
            setChartData(chartData);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="welcome-sec">
            <Container fluid>
                <div>
                    <div className="online-card-bg">
                        <h4 style={{ fontWeight: 'bold' }}>Oil & Gas Data Query</h4>
                    </div>
                    <div className="fullHeightRow-online-card">
                        <h5 style={{ fontWeight: 'bold' }}>Specific Lease Query Results</h5>
                        <div className="query-card">
                            <h6>Date Range:</h6>
                            <MonthPicker
                                endYearGiven // mandatory if end={} is given in YearPicker
                                // year={endYear} // mandatory
                                year={startYear}
                                required={true}
                                value={parseInt(startMonth)}
                                onChange={(startMonth) => {
                                    console.log("Received start month from picker:", startMonth);
                                    setStartMonth(startMonth);
                                }}
                                id={"month"}
                                name={"month"}
                                classes={"dropdown-month"}
                                optionClasses={"option classes"}
                            />
                            <YearPicker
                                start={1925} // default is 1900
                                end={2023} // default is current year
                                reverse // default is ASCENDING
                                disabled={false} // default is false
                                required={true}
                                value={startYear}
                                onChange={(startYear) => setStartYear(startYear)}
                                id={"year"}
                                name={"year"}
                                classes={"dropdown-year"}
                                optionClasses={"option classes"}
                            />
                            <h6 style={{ marginBottom: '0' }}>To</h6>
                            <MonthPicker
                                defaultValue={null}
                                endYearGiven // mandatory if end={} is given in YearPicker
                                // year={startYear} // mandatory
                                required={true} // default is false
                                value={parseInt(endMonth)}

                                onChange={(endMonth) => {
                                    console.log("Received end month from picker:", endMonth);
                                    setEndMonth(endMonth);
                                }}
                                id={"month"}
                                name={"month"}
                                classes={"dropdown-month"}
                                optionClasses={"option classes"}
                            />
                            <YearPicker
                                start={1925} // default is 1900
                                end={2023} // default is current year
                                reverse // default is ASCENDING
                                required={true} // default is false
                                value={endYear}
                                onChange={(endYear) => setEndYear(endYear)}

                                id={"year"}
                                name={"year"}
                                classes={"dropdown-year"}
                                optionClasses={"option classes"}
                            />
                            <div onClick={handleSubmit} className="submit-calendar">
                                <Link to={""}>Submit</Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }} />
                        <h5 style={{ fontWeight: 'bold' }}>Production Data</h5>
                        <Row className="fullHeightRow-search">
                            <Col md="3">
                                <div className="search-criteria-box" >
                                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Search Criteria</p>
                                    <p><span style={{ fontWeight: 'bold' }}>Operator Name:</span> <span style={{ fontWeight: 'normal' }}>{operatorName}</span></p>
                                    <p><span style={{ fontWeight: 'bold' }}>Lease Name:</span> <span style={{ fontWeight: 'normal' }}>{leaseName}</span></p>
                                    <p><span style={{ fontWeight: 'bold' }}>Lease No :</span> <span style={{ fontWeight: 'normal' }}>{leaseNumber}</span></p>
                                    <p><span style={{ fontWeight: 'bold' }}>Well Type:</span> <span style={{ fontWeight: 'normal' }}>{oilGasCode === 'O' ? 'Oil' : 'Gas'}</span></p>
                                    <p><span style={{ fontWeight: 'bold' }}>Date Range:</span> <span style={{ fontWeight: 'normal' }}>{getMonthName(startMonth)} {startYear} - {getMonthName(endMonth)} {endYear}</span></p>
                                </div>
                            </Col>
                            <Col md="9">
                                {isLoading ? (
                                    <div style={{ fontWeight: "bold", textAlign: "center" }}>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : !chartData || chartData.length === 0 ? (
                                    <div style={{ fontWeight: "bold", textAlign: "center" }}>No production data found</div>
                                ) : (
                                    <div className="">
                                        <ResponsiveContainer width="100%" height={500}>
                                            <LineChart
                                                data={chartData}
                                                margin={{
                                                    top: 15,
                                                    right: 20,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" angle={-45} textAnchor="end" domain={['dataMin', 'dataMax']} />
                                                <YAxis yAxisId="left" orientation="left" stroke="#000000" tickFormatter={(tick) => tick.toLocaleString()}>
                                                    <Label value="MCF" angle={-90} position="insideLeft" dx={-10} dy={15} style={{ fontWeight: 'bold', color: '#000000' }} />
                                                </YAxis>
                                                <YAxis yAxisId="right" orientation="right" stroke="#000000" tickFormatter={(tick) => tick.toLocaleString()}>
                                                    <Label value="BBL(s)" angle={90} position="insideRight" dx={10} dy={15} style={{ fontWeight: 'bold', color: '#000000' }} />
                                                </YAxis>
                                                <Tooltip />
                                                <Legend wrapperStyle={{ bottom: '-20px', position: 'relative' }} />
                                                <Line
                                                    type="monotone"
                                                    dataKey="Gas and Casinghead Gas"
                                                    stroke="#FF4500"
                                                    strokeWidth={3}
                                                    yAxisId="left"
                                                />
                                                <Line
                                                    type="monotone"
                                                    dataKey="Oil and Condensate"
                                                    stroke="#008000"
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <div className="download-file">
                            {/* <div className="file-blk">
                                <h6>
                                    <span>
                                        <img src={doc} alt="icon img" />
                                    </span>
                                    Interactive Map and Map Display.xlsx
                                </h6>
                            </div> */}
                            <div className="download-blk">
                                <button className="clr-btn" id="downloadBtn" value="download" onClick={() => {
                                    const csvContent = generateCSV(tableData);
                                    downloadCSV(csvContent, "ProductionData.csv");
                                }}>
                                    <img src={download} alt="icon img" />
                                    Download
                                </button>
                            </div>
                        </div>
                        <div className="table-bg">
                            <div className="map-table">
                                <table style={{ width: '100%' }}> {/* Added inline style here */}
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Operator No</th>
                                            <th>Operator Name</th>
                                            <th>Field Name</th>
                                            <th>Field No</th>
                                            <th>County Name</th>
                                            <th>Monthly Oil Production</th>
                                            <th>Monthly Gas Production</th>
                                            {/* <th>Disposition</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData && currentItems.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.Date}</td>
                                                <td>{item.OperatorNo}</td>
                                                <td>{item.OperatorName}</td>
                                                <td>{item.FieldName || "-"}</td>
                                                <td>{item.FieldNo || "-"}</td>
                                                <td>{item.CountyName || "-"}</td>
                                                <td>{item.OilProduction || "-"}</td>
                                                <td>{item.GasProduction || "-"}</td>
                                                {/* <td>{item.Disposition || "-"}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="pagination-footer">
                            <span className="pagination-left-text">{`${indexOfFirstItem + 1} - ${indexOfLastItem} of ${tableData.length} Result`}</span>
                            <div className="pagination-button-row">
                                <Button className="pagination-left-button" onClick={handlePreviousPage}>
                                    <img src={gradientLeftArrow} alt="icon img" /> Previous
                                </Button>
                                <nav>
                                    <ul className="pagination">
                                        {pageNumbers.map((page, index) => (
                                            <li
                                                key={index}
                                                className={`page-item ${page === currentPage ? "active" : ""}`}
                                            >
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(page)}
                                                >
                                                    {page}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                <Button className="pagination-right-button" onClick={handleNextPage}>
                                    Next <img src={whiteRightArrow} alt="icon img" />
                                </Button>
                            </div>
                            <div className="page-size-row">
                                <span>Page Size</span>
                                <Dropdown
                                    options={options}
                                    onChange={(option) => {
                                        setItemsPerPage(parseInt(option?.label, 10));
                                        setCurrentPage(1); // Optionally reset to the first page
                                    }}
                                    value={itemsPerPage.toString()}
                                    placeholder="10"
                                    className="page-size-dropdown"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default ProductionDataChart;