import React, { useState, useRef, useEffect } from "react";

export const Legend = () => {
    const icons = [
        { path: "/legend_icons/Oil Well NEW.svg", description: "Oil well" },
        { path: "/legend_icons/Gas Well NEW.svg", description: "Gas well" },
        { path: "/legend_icons/Oil and Gas Well.svg", description: "Oil and gas well" },
        { path: "/legend_icons/Permitted Location NEW.svg", description: "Permitted location" },
        { path: "/legend_icons/Dry Hole NEW.svg", description: "Dry hole" },
        { path: "/legend_icons/Shut In Oil NEW.svg", description: "Shut-in oil well" },
        { path: "/legend_icons/Shut In Gas NEW.svg", description: "Shut-in gas well" },
        { path: "/legend_icons/Plugged Oil Well NEW.svg", description: "Plugged oil well" },
        { path: "/legend_icons/Plugged Gas Well NEW.svg", description: "Plugged gas well" },
        { path: "/legend_icons/Pluged Oil : Gas Well.svg", description: "Plugged oil and gas well" },
        { path: "/legend_icons/Injection Disposal NEW.svg", description: "Injection/disposal well" },
        { path: "/legend_icons/Canceled : Abandoned Location NEW.svg", description: "Canceled or abandoned location" },
        { path: "/legend_icons/Other.svg", description: "Other" },
        { path: "/legend_icons/Horizontal Surface.svg", description: "Horizontal surface location" },
        { path: "/legend_icons/Directional Surface.svg", description: "Directional surface location" }
    ];

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            className={`legend ${isOpen ? 'open' : ''}`}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
        >
            <button className="legend-toggle">
                <div className="legend-border">
                    <div className="legend-background">
                        <img src="/legend_icons/legend-toggle.svg" alt="Legend Toggle" className="legend-toggle-icon" />
                    </div>
                </div>
            </button>

            <div className="legend-dropdown">
                <table className="legend-table">
                    <tbody>
                        {icons.map((icon, index) => (
                            <tr key={index} className="legend-row">
                                <td className="legend-cell-icon">
                                    <img src={icon.path} alt={icon.description} className="legend-icon" />
                                </td>
                                <td className="legend-cell-description">
                                    <span>{icon.description}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

};

export default Legend;