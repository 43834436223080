import ReactDOM from "react-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from "./reportWebVitals";
import Routing from "./Routing";
import { Provider } from "react-redux";
import store from "../src/config/Store";

function Main() {
  return (
    <Provider store={store}>
      <Routing />
    </Provider>
  );
  // }
}
ReactDOM.render(<Main />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

