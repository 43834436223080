import {
    wellMapping,
    pipelineMapping,
    codeCodeToCounty
} from "../../constants";
import { formatPipelineAttributesForTable, formatWellAttributesForTable } from "../../utils";

export function TableRowValues({ data }) {
    const isWell = data.properties && data.properties.API;

    // Compute the gas volume
    const gasVolume = parseFloat(data.properties.LSE_GAS_PROD_VOL || 0) + parseFloat(data.properties.LSE_CSGD_PROD_VOL || 0);

    // List of keys you want to display for wells
    const wellDesiredKeys = [
        "API",
        "COUNTY",
        "SYMNUM",
        "COMPLETION",
        "OPERATOR",
        "LAST_PROD",
        "LSE_OIL_PROD_VOL",
        "Gas Volume",
        "LAST_FLARED",
        "FLARE_VOL",
        "TOTAL_DEPT",
        "LATERAL_LENGTH",
        "PLUG_DATE",
        "OPERATOR_CONTACT"
    ];

    // List of keys you want to display for pipelines
    const pipelineDesiredKeys = [
        "PIPE_LENGTH",
        "COUNTY",
        "COMMODITY1",
        "SYS_NM",
        "DIAMETER",
        "OPER_NM",
        "OPS_ID",
        "STATUS_CD",
        "OPERATOR_CONTACT"
    ];

    // Determine which set of keys to use based on the data type
    const desiredKeys = isWell ? wellDesiredKeys : pipelineDesiredKeys;

    return (
        <tr>
            {desiredKeys.map((key, index) => {
                let displayValue;
                if (key === "Gas Volume") {
                    displayValue = gasVolume;
                } else if (key === "COUNTY") {
                    displayValue = codeCodeToCounty[data.properties[key]] || data.properties[key];
                } else if (isWell) {
                    displayValue = formatWellAttributesForTable(key, data.properties[key]);
                } else {
                    displayValue = formatPipelineAttributesForTable(key, data.properties[key]);
                }

                return <td key={index}>{displayValue || 'N/A'}</td>;
            })}
        </tr>
    );
}
