import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-dropdown/style.css";
import App from "./App";
import Header from "./Extends/Header";
import Footer from "./Extends/Footer";
import Aboutus from "./Views/Aboutus/Index";
import Login from "./Views/login/Index";
import Register from "./Views/register/Index";
import Datarequest from "./Views/datarequest/Index";
import Home from "./Views/home/index";
import Pricing from "./Views/pricing/Index";
import Faqs from "./Views/faqs/Index";
import ScrollToTop from "./Views/ScrollToTop/Index";
import { getUserDetail } from "./actions/userActions";
import ProductionDataChart from './Views/ProductionDataChart';
import ForgotPassword from './Views/forgotPassword';
function Routing() {
  const [showing, setShowing] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    handleLoginned();
  }, []);

  const showLoginContact = () => {
    setShowing(true);
  };

  const LogoutContact = () => {
    setShowing(false);
    console.log(showing);
  };

  const handleLoginned = async () => {
    const loggedInUser = await localStorage.getItem("user");
    console.log('handleLoginned - loggedInUser', loggedInUser)
    if (loggedInUser && loggedInUser !== undefined && loggedInUser !== "undefined" && loggedInUser !== null) {
      console.log('loggedInUser', loggedInUser)
      setUser(JSON.parse(loggedInUser));
      setShowing(true);
      getUserData(JSON.parse(loggedInUser).uid);
    } else {
      setUser(null);
      setShowing(false);
    }
  };

  const getUserData = (id) => {
    getUserDetail(id)
      .then((response) => {
        console.log('getUserData', response)

        let userData = response.data;
        setUserData(userData);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <BrowserRouter>
      <header className="pageheader">
        <Header
          showing={showing}
          LogoutContact={LogoutContact}
          userData={userData}
        />
      </header>
      <ScrollToTop />
      {/* <App /> */}
      <Routes>
        {user == null ? (
          <>
            <Route path="/" element={<App />} />
            <Route
              path="/login"
              element={
                <Login showing={showing} showLoginContact={showLoginContact} />
              }
            />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route
              path="/register"
              element={<Register showLoginContact={showLoginContact} />}
            />
            <Route path="/datarequest" element={<Datarequest />} />
            <Route path="/home" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/datarequest" element={<Datarequest />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/production-data-chart" element={<ProductionDataChart />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

          </>
        ) : (
          <>
            <Route path="/" element={<App />} className="ppp" />
            <Route path="/production-data-chart" element={<ProductionDataChart />} />
            <Route path="/datarequest" element={<Datarequest />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/pricing" element={<Pricing />} />

          </>
        )}
      </Routes>
      <footer>
        <Footer />
      </footer>
    </BrowserRouter>
  );
}

export default Routing;
