import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { } from '@fortawesome/fontawesome-free-solid'
import React, { useState } from 'react';
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';



import messages from '../../../src/images/messages-2.png';
import knowladge from '../../../src/images/knowladge.png';
import cardedit from '../../../src/images/card-edit.png';
import data2 from '../../../src/images/data-2.png';
import doc from '../../../src/images/doc.png';
import download from '../../../src/images/download.png';
import send from '../../../src/images/send.png';
import avatar from '../../../src/images/avatar.png';
import robotavatar from '../../../src/images/robotavatar.png';
import roboicon1 from '../../../src/images/robo-icon1.png';
import roboicon2 from '../../../src/images/robo-icon2.png';
import roboicon3 from '../../../src/images/robo-icon3.png';
import reload from '../../../src/images/reload.png';





function Home() {

  return (

    <div>


      <div className='welcome-sec'>
        <Container fluid>
          <div className='card-bg'>

            <Row>
              <Col md='5'>
                <div className='chatbox'>

                  <div className='chat-opt'>

                    <div className='people-chat'>
                      <img src={avatar} alt="icon img" />
                      <p>In product development, MVP stands for Minimum Viable Product?</p>
                    </div>

                    <div className='robot-chat'>
                      <img src={robotavatar} alt="icon img" />
                      <div className='robot-txt'>
                        <p>In product development, MVP stands for Minimum Viable Product. It is a strategy
                          or approach that focuses on building and launching a product with the minimum set
                          of features required to satisfy early customers and gather valuable feedback.</p>
                        <div className='chat-icons'>
                          <ul>
                            <li><img src={roboicon1} alt="icon img" /></li>
                            <li><img src={roboicon2} alt="icon img" /></li>
                            <li><img src={roboicon3} alt="icon img" /></li>
                          </ul>
                          <img src={reload} alt="icon img" />
                        </div>
                      </div>
                    </div>

                    <div className='people-chat'>
                      <img src={avatar} alt="icon img" />
                      <p>In product development, MVP stands for Minimum Viable Product?</p>
                    </div>

                    <div className='robot-chat'>
                      <img src={robotavatar} alt="icon img" />
                      <div className='robot-txt'>
                        <p>In product development, MVP stands for Minimum Viable Product. It is a strategy
                          or approach that focuses on building and launching a product with the minimum set
                          of features required to satisfy early customers and gather valuable feedback.</p>
                        <div className='chat-icons'>
                          <ul>
                            <li><img src={roboicon1} alt="icon img" /></li>
                            <li><img src={roboicon2} alt="icon img" /></li>
                            <li><img src={roboicon3} alt="icon img" /></li>
                          </ul>
                          <img src={reload} alt="icon img" />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className='chattype'>
                    <Form className='chatform'>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="Tell me what do you want?" />
                      </Form.Group>
                      <Button className='clr-btn'><img src={send} alt="icon img" /></Button>
                    </Form>
                  </div>

                </div>
              </Col>
              <Col md='7'>
                <div className='table-box'>
                  <div className='map-blk'>
                    <Map
                      mapboxAccessToken="<Mapbox access token>"
                      initialViewState={{
                        longitude: -122.4,
                        latitude: 37.8,
                        zoom: 14
                      }}
                      style={{ width: 600, height: 400 }}
                      mapStyle="mapbox://styles/mapbox/streets-v9"
                    />
                  </div>
                  <div className='map-table'>
                    <table>
                      <tbody>
                        <tr>
                          <th>Operator</th>
                          <th>API Number</th>
                          <th>Well type</th>
                          <th>Well Classification</th>
                          <th>Field</th>
                          <th>Status</th>
                          <th>Last Update</th>
                          <th>Operator</th>
                          <th>API Number</th>
                          <th>Well type</th>
                          <th>Well Classification</th>
                          <th>Field</th>
                        </tr>
                        <tr>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                          <td><span className='status st-close'>Closed</span></td>
                          <td>04/30/2023</td>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                        </tr>
                        <tr>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                          <td><span className='status st-open'>Open Drilling</span></td>
                          <td>04/30/2023</td>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                        </tr>
                        <tr>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                          <td><span className='status st-close'>Closed</span></td>
                          <td>04/30/2023</td>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                        </tr>
                        <tr>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                          <td><span className='status st-open'>Open Drilling</span></td>
                          <td>04/30/2023</td>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                        </tr>
                        <tr>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                          <td><span className='status st-open'>Open Drilling</span></td>
                          <td>04/30/2023</td>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                        </tr>
                        <tr>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                          <td><span className='status st-open'>Open Drilling</span></td>
                          <td>04/30/2023</td>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                        </tr>
                        <tr>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                          <td><span className='status st-open'>Open Drilling</span></td>
                          <td>04/30/2023</td>
                          <td>Master Resource Development</td>
                          <td>41029401</td>
                          <td>Oil</td>
                          <td>Injection</td>
                          <td>VAN (Shallow)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='download-file'>
                    <div className='file-blk'>
                      <h6><span><img src={doc} alt="icon img" /></span>Interactive Map and Map Display.xlsx</h6>
                    </div>
                    <div className='download-blk'>
                      <button className='clr-btn' id="downloadBtn" value="download"><img src={download} alt="icon img" />Download</button>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div >


    </div >

  );

}



export default Home;
