import {
    wellMapping,
    pipelineMapping
} from "../../constants";
import { codeCodeToCounty } from "../../constants/index.js";

export function TableHeaders({ renderedLayersIds, mapData }) { // Notice the addition of mapData prop
    const hasWell = mapData.some(data => data.properties && data.properties.API);
    const hasPipeline = !hasWell; // We set this based on the absence of well data

    const wellDesiredKeys = [
        "API",
        "COUNTY",
        "SYMNUM",
        "COMPLETION",
        "OPERATOR",
        "LAST_PROD",
        "LSE_OIL_PROD_VOL",
        "Gas Volume",
        "LAST_FLARED",
        "FLARE_VOL",
        "TOTAL_DEPT",
        "LATERAL_LENGTH",
        "PLUG_DATE",
        "OPERATOR_CONTACT"
    ];

    const pipelineDesiredKeys = [
        "PIPE_LENGTH",
        "COUNTY",
        "COMMODITY1",
        "SYS_NM",
        "DIAMETER",
        "OPER_NM",
        "OPS_ID",
        "STATUS_CD",
        "OPERATOR_CONTACT"
    ];

    const headerUnits = {
        "PIPE_LENGTH": " (miles)",
        "DIAMETER": " (in)",
        "LSE_OIL_PROD_VOL": " (barrels/month)",
        "Gas Volume": " (mcf/month)",
        "TOTAL_DEPT": " (feet)",
        "LATERAL_LENGTH": " (feet)",
        "FLARE_VOL": " (mcf/month)"
    };

    // Determine which set of keys to use based on the presence of well data
    let desiredKeys = hasWell ? wellDesiredKeys : pipelineDesiredKeys;

    return (
        <tr>
            {desiredKeys.map((key, index) => {
                let displayName = key === "Gas Volume" ? key : wellMapping[key] || pipelineMapping[key] || key;
                displayName += headerUnits[key] || ""; // Append the unit if it exists
                return <th key={index}>{displayName}</th>;
            })}
        </tr>
    );
}
