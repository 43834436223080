import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { BsLightningChargeFill } from "react-icons/bs";

import EditProfile from "./EditProfile";

const profile = (props) => {
  return (
    <div>
      <Modal
        className="profile-modal close-btn"
        show={props.show}
        onHide={props.close}
      >
        <Modal.Header closeButton>
          <h3>Profile</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="profile-main">
            <div className="prof-detail">
              <div className="prof-img">
                {/* <img src={profileicon} alt="icon img" /> */}
                <div className="profile-circle">
                  <p>
                    {props?.userData?.displayName
                      ? props?.userData?.displayName.split("")[0]
                      : ""}
                  </p>
                  <p>
                    {props?.userData?.displayName
                      ? props?.userData?.displayName.split(" ")[1][0]
                      : ""}
                  </p>
                </div>
                <div className="prof-email">
                  <h5>
                    {props.userData?.displayName
                      ? props.userData?.displayName
                      : ""}
                  </h5>
                  <p>{props.userData?.email ? props.userData?.email : ""}</p>
                </div>
              </div>
              <button onClick={props.updateShow}>Edit Profile</button>
            </div>

            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Edgar"
                  value={
                    props.userData?.displayName
                      ? props.userData?.displayName.split(" ")[0]
                      : ""
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Thompson"
                  value={
                    props.userData?.displayName
                      ? props.userData?.displayName.split(" ")[1]
                      : ""
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Organization (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vektora"
                  value={
                    props.userData?.origanization
                      ? props.userData?.origanization
                      : ""
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="edgarthomp@gmail.com"
                  value={props.userData?.email ? props.userData?.email : ""}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="************" />
              </Form.Group>
              <div className="google-login">
                <Link to={""}>
                  <BsLightningChargeFill className="clr-icon" /> Upgrade Account
                </Link>
                <p>
                  Upgrade your account now and get 50% discount for your first
                  membership
                </p>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <EditProfile
        editShow={props.editShow}
        close={props.updateClose}
        userData={props?.userData}
      />
    </div>
  );
};

export default profile;
