import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

function PriceCard(props) {
  console.log(props.show);
  const show = {
    display: "block"
  }
  const hide = {
    display: 'none'
  }
  console.log(hide);
  console.log(show);

  const [isUserLoggedIn, setUserData] = useState(localStorage.getItem("user") ? true : false);
  const navigate = useNavigate();

  const redirectToRegister = () => {
    console.log('redirectToRegister')
    navigate("/register");
  }

  return (
    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3">
      <Tab eventKey="home" >
        {
          props.show === true ?

            <Row>
              <Col md='6'>
                <div className='price-box w-box'>
                  <small>Free Plan</small>
                  <h3>$01<span>/Year</span></h3>
                  <h4>Free Plan</h4>
                  <ul>
                    <li>12 messages a month</li>
                    {/* <li>1,000 tokens permonth included</li>
                    <li>1,000 tokens permonth included</li> */}
                  </ul>
                  {/* <button>Choose Plan</button> */}
                </div>
              </Col>
              <Col md='6'>
                <div className='price-box b-box'>
                  <small>Premium Plan</small>
                  <h3>$35,99<span>/Year</span></h3>
                  <h4>Pro Plan</h4>
                  <ul>
                    <li>Unlimited messages a month</li>
                    <li>Early access to new features</li>
                    <li>Ad-free experience</li>
                  </ul>
                  <button onClick={props.triggerCheckout}>Get Premium</button>
                </div>
              </Col>
            </Row> :
            <Row>
              <Col md='6'>
                <div className='price-box w-box'>
                  <small>Free Plan</small>
                  <h3>$0<span>/month</span></h3>
                  <h4>Free Plan</h4>
                  <ul>
                    <li>12 messages a month</li>

                  </ul>
                  {/* <button>Choose Plan</button> */}
                </div>
              </Col>
              <Col md='6'>
                <div className='price-box b-box'>
                  <small>Premium Plan</small>
                  <h3>$29.99<span>/month</span></h3>
                  <h4>Premium Plan</h4>
                  <ul>
                    <li>Unlimited messages a month</li>
                    <li>Early access to new features</li>
                    <li>Ad-free experience</li>
                  </ul>
                  {isUserLoggedIn ?
                    <button onClick={props.triggerCheckout}>Get Premium</button>
                    :


                    <button onClick={redirectToRegister}>Sign In To Get Premium</button>}
                </div>
              </Col>
            </Row>
        }
      </Tab>
    </Tabs>
  );
}

export default PriceCard;