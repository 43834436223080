import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import Profile from "../components/profile";
import Header_Logo from "../images/Header_Logo.svg";
import proficon1 from "../images/prof-icon1.png";
import proficon2 from "../images/prof-icon2.png";
import proficon3 from "../images/prof-icon3.png";
import { useDispatch } from "react-redux";
import { getAuth, signOut } from "firebase/auth";
import { LOGOUT } from "../store/actions/types";

function Header({ showing, LogoutContact, userData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const setExpand = () => {
    setExpanded(false);
    // window.scrollTo(0, 0);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateShow = () => {
    setShow(false);
    setEditShow(true);
  };
  const updateClose = () => {
    setEditShow(false);
  };

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then((response) => {
        dispatch({
          type: LOGOUT,
        });
        localStorage.removeItem("user");
        navigate("/login");
        window.location.reload(true);
        LogoutContact();
      })
      .catch((error) => { });
  };

  return (
    <div className="main-header">
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img className="headerLogo" src={Header_Logo} alt="sitelogo" />
            <span className="brand-text">PetroTech.AI</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <NavLink
                exact="true"
                as={Link}
                to={"/pricing"}
                onClick={setExpand}
              >
                Pricing
              </NavLink>
              <NavLink
                style={{ textDecoration: "none" }}
                exact="true"
                as={Link}
                to={"/datarequest"}
                onClick={setExpand}
              >
                Data Request
              </NavLink>
              <NavLink
                style={{ textDecoration: "none" }}
                exact="true"
                as={Link}
                to={"/faqs"}
                onClick={setExpand}
              >
                FAQ
              </NavLink>
              {showing === false ? (
                <NavLink
                  className={showing === false ? "hdr-btn" : "hide-btn"}
                  exact="true"
                  as={Link}
                  to={"/login"}
                  onClick={setExpand}
                  style={{ textDecoration: "none" }}
                >
                  Login
                </NavLink>
              ) : (
                ""
              )}
            </Nav>
          </Navbar.Collapse>
          {showing === true ? (
            <>
              {/* <div className="profile-circle-header">
                <p>
                  {userData?.displayName
                    ? userData?.displayName.split("")[0]
                    : ""}
                </p>
                <p>
                  {userData?.displayName
                    ? userData?.displayName.split(" ")[1][0]
                    : ""}
                </p>
              </div> */}
              <NavDropdown
                title={userData?.displayName ? userData?.displayName : ""
                }
                id="basic-nav-dropdown"
                className="dropwDown-test"
              >
                <NavDropdown.Item href="#action/3.1" onClick={handleShow}>
                  <img src={proficon1} alt="logo" className="header-profile" />
                  My Account
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  <img src={proficon2} alt="logo" />
                  Setting
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4" onClick={logout}>
                  <img src={proficon3} alt="logo" /> Sign Out{" "}
                </NavDropdown.Item>
              </NavDropdown>
            </>
          ) : (
            ""
          )}
        </Container>
      </Navbar>
      <Profile
        show={show}
        close={handleClose}
        updateShow={updateShow}
        editShow={editShow}
        updateClose={updateClose}
        userData={userData}
      />
    </div>
  );
}

export default Header;
