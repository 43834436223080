import "react-toastify/dist/ReactToastify.css";
import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import messages from "../src/images/messages-2.png";
import knowladge from "../src/images/knowladge.png";
import send from "../src/images/send.png";
import download from "../src/images/download.png";
import doc from "../src/images/doc.png";
import robotavatar from "../src/images/robotavatar.png";
import PetroTechAI from "../src/images/PetroTechAI.png";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import {
  downloadCSV,
  incrementMessageCount
} from "./utils";
import { TableRowValues } from "./components/Table/TableRowValues";
import { TableHeaders } from "./components/Table/TableHeaders";
import { MapComponent } from "./components/Map";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GUEST_MESSAGE_COUNT } from "./constants";
import { getUserDetail } from "./actions/userActions";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

function App() {

  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRendered, setIsRendered] = useState(true);
  const [mapData, setMapData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const chatContainerRef = useRef(null);
  const [renderedLayersIds, setRenderedLayers] = useState(null);
  const [botMessage, setBotMessage] = useState("")
  const [currentAssistantMessage, setCurrentAssistantMessage] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [lastMapboxFilter, setLastMapboxFilter] = useState(null);

  let toRender = [];
  if (mapData) {
    const wells = mapData.filter(data => data.properties && data.properties.API);
    toRender = wells.length > 0 ? wells : mapData;
  }


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]); // This ensures the effect runs every time chatHistory changes

  //Handle routing if user close tab and come back to enter url
  useEffect(() => {
    handleLogin();
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    console.log('Query - ', query)
    if (document.referrer === 'https://checkout.stripe.com/') {
      console.log('Stripe')
      const userId = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).uid;

      getUserDetail(userId)
        .then((response) => {
          console.log('Redirected from checkout: getUserData', response)

          let userData = response.data;
          localStorage.setItem("user", JSON.stringify(userData));
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }

    if (query.get("canceled")) {
      console.log('Canceled')
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleLogin = async () => {
    const loggedInUser = await localStorage.getItem("user");
    console.log('handleLogin', loggedInUser)

    if (loggedInUser && loggedInUser !== undefined && loggedInUser !== null) {
      // navigate("/");
    } else {
      // navigate("/login");
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!message.trim()) return;

    // Increment the message count for the guest user
    const currentCount = incrementMessageCount();
    if (currentCount > GUEST_MESSAGE_COUNT && !localStorage.user) {
      alert("You have reached the maximum number of messages allowed for a guest user. Please sign up or login to continue.");
      return;
    }

    addToChatHistory({
      content: message,
      role: "user",
    });

    let accumulatedMessage = "";
    setCurrentAssistantMessage(""); // Clear the currentAssistantMessage before adding new messages
    setMessage("");  // Clear the input message
    setIsLoading(true);
    setIsRendered(false);

    const uid = localStorage.user && JSON.parse(localStorage.user).uid;

    try {
      const response = await fetch("https://base---ai-response-qxvz4kyolq-uc.a.run.app/", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, session: { chat_history: chatHistory }, lastMapboxFilter, user: { uid } }),
      });

      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      let isStreamingComplete = false;  // Flag to indicate when streaming is complete
      setIsLoading(false);

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        if (value.includes("[END_OF_STREAM]")) {
          isStreamingComplete = true;
        }

        if (!isStreamingComplete) {
          setCurrentAssistantMessage(prevMessage => prevMessage + value);
        } else {
          accumulatedMessage += value;
        }
      }

      console.log(accumulatedMessage);
      const [_, jsonPayload] = accumulatedMessage.split("[END_OF_STREAM]");
      const parsedPayload = JSON.parse(jsonPayload);


      if (parsedPayload.mapboxFilter) {
        setLastMapboxFilter(JSON.stringify(parsedPayload.mapboxFilter));
        console.log("Response: mapboxFilter", JSON.stringify(parsedPayload.mapboxFilter));
        handleMapboxFilterResponse(parsedPayload);
      } else {
        handleErrorResponse('Error: no mapboxFilter found in response.');
      }

      setCurrentAssistantMessage("");  // Reset the currentAssistantMessage

      if (parsedPayload.chat) {
        setChatHistory(parsedPayload.chat);
      } else {
        handleErrorResponse('Error: no chat found in response.');
      }

    } catch (error) {
      console.error("Error:", error);
      handleErrorResponse();
    }
    setIsLoading(false);
    setIsRendered(true);
  };

  const getAIResponse = async () => {
    console.log('getAIResponse - chatHistory', chatHistory)
    return await axios.post(
      "https://us-central1-rrc-mapping.cloudfunctions.net/ai-response",

      { message, session: { chat_history: chatHistory } },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleMapboxFilterResponse = (response) => {
    const filter = response.mapboxFilter;
    setFilterData(filter);

    console.log("Response: filter", filter);
    console.log("Response: answer", response.chat);  // Updated this line

    // try {
    //   let botMessage = response.chat;  // Simplified this line

    //   if (response.chat && !response.mapboxFilter) {
    //     updateLastBotMessageInChatHistory(botMessage);
    //     clearUserMessage();
    //   }
    // } catch (e) {
    //   console.error("Error:", e);
    //   handleErrorResponse();
    // }
  };

  const handleErrorResponse = (errorMsg = "I'm sorry, I was not able to return your query, possibly due to a network error or timeout. Please refresh the page and try again.") => {
    addToChatHistory({
      role: "assistant",
      content: errorMsg,
    });
    clearUserMessage();
  };

  const addToChatHistory = (messageObj) => {
    setChatHistory((prevHistory) => [...prevHistory, messageObj]);
  };

  const updateLastBotMessageInChatHistory = (botMessage) => {
    setChatHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      newHistory[newHistory.length - 1].role = "assistant";
      newHistory[newHistory.length - 1].content = botMessage;
      return newHistory;
    });
  };

  const clearUserMessage = () => {
    setMessage("");
  };
  console.log('chatHistory', chatHistory)
  // console.log('mapData', mapData && mapData.length ? mapData.length : null)
  return (
    <div>
      <div className="welcome-sec">
        <Container fluid>
          <div className="home-card-bg">
            <Row className="fullHeightRow">
              <Col md="5">
                <div className="chatbox">
                  {chatHistory.length > 0 ? (
                    <div className="chat-scroll" ref={chatContainerRef}>
                      {chatHistory.map((item, index) => (
                        <>
                          {item?.role === 'user' && (
                            <div className="chat-opt" key={index}>
                              <div className="people-chat">
                                <img src={PetroTechAI} alt="icon img" />
                                <p>{item?.content}</p>
                              </div>
                            </div>
                          )}

                          {item?.role === 'assistant' && (
                            <div className="chat-opt" key={index}>
                              <div className="robot-chat">
                                <img src={robotavatar} alt="icon img" />
                                <div className="robot-txt">
                                  <p>{item?.content}</p>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Render the loading ellipsis only after the last user message in the chat history */}
                          {isLoading && index === chatHistory.length - 1 && (
                            <div className="chat-opt" key={index}>
                              <div className="robot-chat">
                                <img src={robotavatar} alt="icon img" />
                                <div className="robot-txt">
                                  <p className="loading-ellipsis"></p>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      {/* Render the currentAssistantMessage after all chat messages */}
                      {currentAssistantMessage.trim() && (
                        <div className="robot-chat">
                          <img src={robotavatar} alt="icon img" />
                          <div className="robot-txt">
                            <p>{currentAssistantMessage}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="chat-opt">
                      <img
                        className="chatLogo"
                        src={PetroTechAI}
                        alt="icon img"
                      />
                      <h3>Welcome to PetroTech.AI</h3>
                      <p style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        PetroTech.AI is your personal AI-powered oil and gas
                        analyst, ready to help you navigate data and provide you
                        valuable insights. We're here to make your job easier.
                        Let's get started! Try asking PetroTech to show you some
                        data like, "Show me all the wells in Andrews county."
                      </p>
                      <ul>
                        <li>
                          <Link to={"/pricing"}>
                            <img src={messages} alt="icon img" />
                            Pricing
                          </Link>
                        </li>
                        <li>
                          <Link to={"/faqs"}>
                            <img src={messages} alt="icon img" />
                            FAQ
                          </Link>
                        </li>
                        <li>
                          <Link to={"/datarequest"}>
                            <img src={knowladge} alt="icon img" />
                            Data Request
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}

                  {


                    <div className="chatHistory">
                      <div className="chattype">
                        <Form className="chatform" onSubmit={handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control
                              className="auto-expand"
                              as="textarea"
                              placeholder={chatHistory.length === 0 ? "How can I help you?" : "Send a message"}
                              value={message}
                              disabled={isLoading || !isRendered}  // Disable the chat input when isLoading is true
                              onChange={(e) => {
                                handleChange(e);
                                const target = e.target;

                                // Reset height to auto to get the correct scrollHeight
                                target.style.height = '52px';  // Resetting to initial height every time

                                if (target.scrollHeight > 52) {
                                  target.style.height = `${Math.min(target.scrollHeight, 150)}px`;
                                }
                                // Automatically scroll to the bottom
                                target.scrollTop = target.scrollHeight;
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                  e.preventDefault();
                                  handleSubmit(e);
                                }
                              }}
                            />
                          </Form.Group>
                          <Button disabled={isLoading} className="clr-btn" onClick={handleSubmit}>
                            <img src={send} alt="icon img" />
                          </Button>
                        </Form>
                      </div>
                    </div>



                  }



                </div >
              </Col >
              <Col md="7">
                <div className="table-box">
                  <div className="map-blk">
                    <MapComponent
                      data={mapData}
                      setMapData={setMapData}
                      filter={filterData}
                      addToChatHistory={addToChatHistory}
                      message={message}
                      setRenderedLayers={setRenderedLayers}
                      setMessage={setMessage}
                      botMessage={botMessage}
                      updateFilterStatus={setIsFilterApplied}
                    />
                  </div>

                  {renderedLayersIds && mapData && isFilterApplied ? (
                    <>
                      <div className="map-table">
                        <table>
                          <thead>
                            {renderedLayersIds ? <TableHeaders renderedLayersIds={renderedLayersIds} mapData={mapData} /> : null}
                          </thead>
                          <tbody>
                            {toRender.slice(0, 100).map((data, index) => <TableRowValues key={index} data={data} />)}
                          </tbody>
                        </table>
                      </div>
                      <div className="download-file">
                        {/* <div className="file-blk">
                          <h6>
                            <span>
                              <img src={doc} alt="icon img" />
                            </span>
                            Interactive Map and Map Display.xlsx
                          </h6>
                        </div> */}

                        <div className="download-blk">
                          <button
                            className="clr-btn"
                            id="downloadBtn"
                            value="download"
                            onClick={() => downloadCSV(mapData)}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{
                                width: '2em',
                                height: '2em',
                                marginRight: '4px',
                                marginTop: '1px',
                                background: `url(${download}) no-repeat`,
                                backgroundSize: 'cover',
                                backgroundPosition: '-2px 0'  // Adjust this value to shift the image and crop the space
                              }}></div>
                              Download
                            </div>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row >
          </div >
        </Container >
      </div >
    </div >
  );
}

export default App;
