import Legend from "./Legend";
import ReactMapGL, { Popup } from "react-map-gl";
import {
    FIPStoDistrictCode,
} from "../constants/index";
import {
    generateUrl,
    getCurrentMonthAndYear,
    isFalsyOrZeroes,
    computeCenterLocation,
    generatePopupContent
} from "../utils";
import { Link, NavLink } from "react-router-dom";
import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';

export function MapComponent({ data, setMapData, filter, addToChatHistory, message, setRenderedLayers, setMessage, botMessage, updateFilterStatus }) {
    const mapRef = useRef(null);
    const [viewState, setViewState] = React.useState({
        longitude: -98.759452,
        latitude: 31.340424,
        zoom: 5.29,
    });
    const [popupPosition, setPopupPosition] = useState(null);
    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [productionDataUrl, setProductionDataUrl] = useState(null);
    const [isFilterApplied, setIsFilterApplied] = useState(!!filter);
    const [draw, setDraw] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [polygonDrawn, setPolygonDrawn] = useState(false);
    const [isSatelliteView, setIsSatelliteView] = useState(false);
    const [currentMapStyle, setCurrentMapStyle] = useState("mapbox://styles/chancew/cln3c49ji06o201p98zsz3srx");  // Your base map URL
    const satelliteStyle = "mapbox://styles/chancew/cln3eghp606ju01phfub21op4";  // Replace with your satellite map URL
    const [currentPolygon, setCurrentPolygon] = useState(null);
    const [currentFilters, setCurrentFilters] = useState(null);

    const applyFiltersToMap = async () => {
        setViewState({
            longitude: -98.759452,
            latitude: 31.340424,
            zoom: 5,
        });

        console.log('applyFiltersToMap')
        const map = mapRef.current.getMap();
        const layers = [
            "final-bottom-file", "final-line-file", "final-surface-file", "pipelines", "pipelines 1", "final-line-file 1"
        ];

        function parseIfJSON(input) {
            try {
                return JSON.parse(input);
            } catch (e) {
                return input;
            }
        }

        const filterArray = parseIfJSON(filter);
        let filteredMapData;

        try {
            console.log('Filter typeof : ', typeof filter);
            filteredMapData = map.queryRenderedFeatures({ layers, filter: filterArray });
            console.log("Filtered Features using queryRendered: ", filteredMapData);

            // layers.forEach((layer) => {
            //     map.setFilter(layer);
            // });


            // const originalData = map.queryRenderedFeatures({ layers });
            // console.log("Features after nulling and before filter: ", originalData);
            console.log('filter', filter)

            const sourceLayers = ["final_bottom_file", "final_surface_file", "pipelines", "final_line_file"];
            console.log('fitlerArray', filterArray)

            layers.forEach((layer) => {
                map.setFilter(layer, filterArray);
            });

            // Wait for the map to become idle after applying new filters
            await new Promise(resolve => map.once('idle', resolve));

            // setCurrentFilters(filterArray);
            let sourceData = []

            sourceLayers.forEach((sourceLayer) => {
                const data = map.querySourceFeatures("composite", {
                    sourceLayer: sourceLayer,
                    filter: filterArray
                });
                sourceData = sourceData.concat(data);
            });

            console.log('Filtered features using querySource', sourceData)

            // filteredMapData = map.queryRenderedFeatures({ layers });


            if (!sourceData.length) {
                throw new Error()
            } else if (sourceData.length) {
                console.log("Filtered map data found: setting message", botMessage)
                // addToChatHistory({
                //     role: "assistant",
                //     content: botMessage, // Empty bot response for now
                // })
            }


            const averageLocation = computeCenterLocation(sourceData);
            console.log('averageLocation', averageLocation)
            const layerIds = [];
            setIsFilterApplied(true);
            updateFilterStatus(true);


            sourceData.forEach(feature => {
                if (feature.layer && feature.layer.id && !layerIds.includes(feature.layer.id)) {
                    layerIds.push(feature.layer.id);
                }
            });

            console.log('layerIds', layerIds);
            setRenderedLayers(layerIds);

            // if (averageLocation && averageLocation.longitude) {
            //     map.flyTo({
            //         center: [averageLocation.longitude, averageLocation.latitude],
            //         zoom: 8,
            //     });
            // } else {
            setViewState({
                longitude: -98.759452,
                latitude: 31.340424,
                zoom: 5,
            });
            // }
            setMapData(sourceData);

        } catch (error) {
            console.log("applyFiltersToMap Error:", error);

            addToChatHistory({
                role: "assistant",
                content: "Sorry, but your query didn't return any data. Try refreshing the page and resubmitting, or rephrasing your question by adding more details."
            })
        }
        console.log('data', data)
    };

    React.useEffect(() => {
        if (filter) {
            applyFiltersToMap();
        }
    }, [filter]);



    const toggleSatelliteView = () => {
        const map = mapRef.current.getMap();
        const newStyle = isSatelliteView ? "mapbox://styles/chancew/cln3c49ji06o201p98zsz3srx" : satelliteStyle;

        setIsSatelliteView(!isSatelliteView);
        map.setStyle(newStyle);

        map.once('style.load', () => {
            // Once the style is loaded, wait for the map to become idle
            map.once('idle', () => {
                const layers = [
                    "final-bottom-file",
                    "final-line-file",
                    "final-surface-file",
                    "pipelines",
                    "pipelines 1",
                    "final-line-file 1"
                ];

                // Function to check if all layers are loaded
                const areAllLayersLoaded = () => {
                    return layers.every(layer => map.getLayer(layer));
                };

                const applyPendingFilters = () => {
                    // Apply general filters first (if any)
                    if (currentFilters) {
                        layers.forEach((layer) => {
                            map.setFilter(layer, currentFilters);
                        });
                    }

                    // Apply specific filters next
                    if (isFilterApplied) {
                        applyFiltersToMap();
                    }

                    // Finally, apply the polygon filter, if a polygon exists
                    if (currentPolygon) {
                        handleDrawEvents(currentPolygon, draw);
                    }
                };

                const checkLayersAndApplyFilters = () => {
                    if (areAllLayersLoaded()) {
                        applyPendingFilters();
                    } else {
                        // If not all layers are loaded, wait for a short duration and check again
                        setTimeout(checkLayersAndApplyFilters, 50);  // Check every 50ms
                    }
                };

                checkLayersAndApplyFilters();
            });
        });
    };

    const handleDrawEvents = (e, drawInstance) => {

        const data = drawInstance.getAll();
        console.log('handleDrawEvents ', data)
        if (data.features.length > 0) {
            setPolygonDrawn(true);
            const selected = data.features[0];
            setCurrentPolygon(selected);

            // Define your layers just once at the top
            const layers = ["final-bottom-file", "final-line-file", "final-surface-file", "pipelines", "pipelines 1", "final-line-file 1"];

            // Set the filter immediately based on the selected polygon
            layers.forEach(layer => {
                mapRef.current.getMap().setFilter(layer, ['within', selected]);
            });

            // Force a render with a slight timeout before querying rendered features
            setTimeout(() => {
                const featuresWithinPolygon = mapRef.current.getMap().queryRenderedFeatures({ layers })
                    .filter(feature => {
                        switch (feature.geometry.type) {
                            case "Point":
                                return turf.booleanPointInPolygon(feature.geometry.coordinates, selected);
                            case "Polygon":
                                return turf.booleanOverlap(feature.geometry, selected);
                            case "LineString":
                                return turf.booleanIntersects(feature.geometry, selected);
                            default:
                                return false;
                        }
                    });

                setMapData(featuresWithinPolygon);
                setRenderedLayers(layers);
                setIsFilterApplied(true);
                updateFilterStatus(true);

                // If there are features within the polygon, zoom into the polygon
                if (featuresWithinPolygon.length > 0) {
                    const bbox = turf.bbox(selected);

                    // Calculate the height of the data table in pixels
                    const dataTableHeight = window.innerHeight * 0.255;

                    // Adjust bottom padding to account for the data table
                    const padding = {
                        top: 20,
                        bottom: 20 + dataTableHeight,
                        left: 20,
                        right: 20
                    };

                    mapRef.current.getMap().fitBounds(bbox, { padding: padding });
                } else {
                    console.log("No features found within the drawn polygon.");
                }

            }, 50);  // A delay of 50ms to ensure the map recognizes the new filter

        } else {
            setPolygonDrawn(false);
        }
    };

    const removeFilter = (filter) => {
        const map = mapRef.current.getMap();
        const layers = [
            "final-bottom-file",
            "final-line-file",
            "final-surface-file",
            "pipelines", "pipelines 1", "final-line-file 1"
        ];

        layers.forEach((layer) => {
            map.setFilter(layer, null);
        });


        if (draw) {
            draw.deleteAll(); // Remove the drawn polygon
        }

        setPolygonDrawn(false);  // Reset the polygonDrawn state

        console.log('filter', filter);
        setIsFilterApplied(false);  // Reset the filter state
        updateFilterStatus(false);

    }

    const handleMapLoad = () => {
        console.log("Map Loaded and handleMapLoad function called");
        const map = mapRef.current.getMap();


        // If draw is already defined, first remove it to ensure no lingering controls or listeners
        if (draw) {
            // Remove event listeners
            map.off("draw.create");
            map.off("draw.delete");
            map.off("draw.update");
            map.off("draw.modechange");

            // Remove the draw control from the map
            map.removeControl(draw);
            setDraw(null); // Set draw state to null
        }

        // Now, add the draw control
        const drawInstance = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
            },
            defaultMode: "simple_select",
        });

        map.addControl(drawInstance);
        setDraw(drawInstance); // This will set the draw instance to state

        // Attach the draw events
        map.on("draw.create", (e) => handleDrawEvents(e, drawInstance));
        map.on("draw.delete", (e) => handleDrawEvents(e, drawInstance));
        map.on("draw.update", (e) => handleDrawEvents(e, drawInstance));

        map.on("draw.modechange", (e) => {
            if (e.mode === "draw_polygon") {
                map.off("click", handleLayerClick);
            } else if (e.mode === "simple_select") {
                map.on("click", handleLayerClick);
            }

            if (e.mode.startsWith("draw_")) {
                setIsDrawing(true);
            } else {
                setIsDrawing(false);
            }
        });

        map.on("draw.delete", () => {
            setPolygonDrawn(true);
        });

        // If a filter is applied, re-apply it after the style change
        if (isFilterApplied) {
            applyFiltersToMap();
        }

    };

    const handleLayerClick = (event) => {
        if (isDrawing) {
            return;  // Exit the function early if drawing
        }
        console.log('handleLayerClick', event)
        const map = mapRef.current.getMap();
        const features = map.queryRenderedFeatures(event.point, {
            layers: ["final-bottom-file", "pipelines", "final-line-file", "pipelines 1", "final-line-file 1"],
        });
        console.log('features', features)
        if (features.length) {
            const clickedFeature = features[0];
            console.log("clickedFeature: click ", clickedFeature);
            const { month, year } = getCurrentMonthAndYear();
            const properties = clickedFeature.properties;
            let leaseName = properties["LEASE_NAME"];
            let leaseNumber = properties["LEASE_ID"];
            let wellType = properties["OIL_GAS_CO"];
            let district = FIPStoDistrictCode[properties["COUNTY"]];
            let startMonth = "06";
            let startYear = "2022";
            let endMonth = month;
            let endYear = year;
            let symNumValue = properties["SYMNUM"];

            const productionDataUrl = generateUrl(
                leaseName,
                leaseNumber,
                wellType,
                district,
                startMonth,
                startYear,
                endMonth,
                endYear,
                symNumValue
            );

            const newPopupPosition = [event.lngLat.lng, event.lngLat.lat];

            setProductionDataUrl(productionDataUrl);
            setHoveredFeature(clickedFeature);
            setPopupPosition(newPopupPosition);
        } else {
            setHoveredFeature(null);
        }
    };

    const handleClosePopup = () => {
        setHoveredFeature(null);
        setPopupPosition(null);
    };

    return (
        <ReactMapGL
            {...viewState}
            ref={mapRef}
            onLoad={handleMapLoad}
            onMove={(evt) => setViewState(evt.viewState)}
            mapStyle={currentMapStyle}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} // Store your token in .env and access like this for security reasons
            onClick={handleLayerClick} // Add this line
        >

            <Legend />  {/* Include the Legend component here */}


            {(isFilterApplied || polygonDrawn) ? (<Button
                style={{
                    position: "absolute",
                    top: "0", // Start positioning from the top
                    left: "0%", // Adjust as needed
                    marginLeft: "10px",
                    marginTop: "1vh", // Adjust as needed; this will push the button down from the top
                    width: "120px",
                    height: "40px",
                    zIndex: 1,
                }}
                value="reset"
                className="clr-btn-reset"
                onClick={removeFilter}
            >
                Reset Map
            </Button>) : null
            }
            {/* Satellite Toggle Button */}
            <button className={`satellite-toggle ${isSatelliteView ? 'active' : ''}`} onClick={toggleSatelliteView}>
                <div className="satellite-border">
                    <div className="satellite-background">
                        <img src="/legend_icons/satellite_svg.svg" alt="Satellite Toggle" className="satellite-toggle-icon" />
                    </div>
                </div>
            </button>
            {hoveredFeature && popupPosition ? (
                <Popup
                    longitude={popupPosition[0]}
                    latitude={popupPosition[1]}
                    closeButton={false}
                    closeOnClick={false}
                    anchor="bottom"
                    dynamicPosition={false}
                >
                    <div className="close-popup" onClick={handleClosePopup}>x</div>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ borderRight: '1px solid lightgrey', paddingRight: '10px', paddingLeft: '10px' }}>Title</th>
                                <th style={{ paddingLeft: '10px' }}>Value</th>
                            </tr>
                            {generatePopupContent(hoveredFeature).map(({ displayName, displayValue }, index) => (
                                displayName && displayValue ? (
                                    <tr key={index}>
                                        <td style={{ borderRight: '1px solid lightgrey', paddingRight: '10px', paddingLeft: '10px' }}>{displayName}</td>
                                        <td style={{ paddingLeft: '10px' }}>{displayValue}</td>
                                    </tr>
                                ) : null
                            ))}
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {hoveredFeature.properties["LEASE_ID"] && !isFalsyOrZeroes(hoveredFeature.properties["LEASE_ID"]) ?
                                        <NavLink
                                            className="prod-btn button-link"  // Apply the button CSS class here
                                            exact="true"
                                            as={Link}
                                            state={hoveredFeature.properties}
                                            to={{
                                                pathname: "/production-data-chart",
                                                state: hoveredFeature.properties,
                                            }}
                                        >
                                            Production Data
                                        </NavLink>
                                        : null}
                                </td>
                                <td>
                                    {hoveredFeature.properties["LEASE_ID"] && !isFalsyOrZeroes(hoveredFeature.properties["LEASE_ID"]) ?
                                        <a
                                            className="clr-btn-alert button-link"  // Apply the button CSS class here
                                            href={productionDataUrl ? productionDataUrl : null}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            RRC Production Data
                                        </a> : null}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Popup>
            ) : null
            }
        </ReactMapGL >
    );
}
