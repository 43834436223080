import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { regex } from "../../utils/regex";
import { db } from "../../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { LOGIN } from "../../store/actions/types";
import { signUp } from "../../actions/socialLogin";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container, Form, Button } from "react-bootstrap";
import loginicon from "../../../src/images/login-img.png";
import { getUserDetail } from "../../actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

function Register({ showLoginContact }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSubmit = () => {
    // if (!firstName) {
    //   toast("First Name Required");
    // } else if (!lastName) {
    //   toast("Last Name Required");
    // } else 

    if (!email) {

      toast("Email Required");
    } else if (!regex.validateEmail(email)) {
      toast("Invalid Email");
    } else if (!password) {
      toast("Passsword Required");
    } else if (!confirmPassword) {
      toast("Confirm Passsword Required");
    } else if (password !== confirmPassword) {
      toast("Passsword, Confirm Password Not Matched");
    } else {
      console.log({ firstName, lastName, email, password, confirmPassword });
      signUp(email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          let uid = user.uid.toString();
          await setDoc(doc(db, "Users", uid), {
            uid: user.uid,
            displayName: regex.isEmpty(firstName)
              ? ""
              : firstName + " " + lastName,
            email: regex.isEmpty(user.email) ? "" : user.email,
            accessToken: user.accessToken,
            stripeCustomerId: "",
            paidMember: false,
          });
          getUser(uid);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorMessage) {
            console.log("errorMessage", errorCode);
            if (errorCode === "auth/email-already-in-use") {
              toast("User Already Exist!");
            } else {
              toast("Signup Failed");
            }
          }
        });
    }
  };

  const getUser = (id) => {
    getUserDetail(id)
      .then((response) => {
        let userData = response.data;
        dispatch({
          type: LOGIN,
          payload: { userData },
        });
        localStorage.setItem("user", JSON.stringify(userData));
        toast("Signup Successful!");
        showLoginContact();
        navigate("/pricing");
        window.location.reload(true);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const loginWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        let user = result.user;
        let uid = user.uid.toString();
        await setDoc(doc(db, "Users", uid), {
          uid: user.uid,
          phoneNumber: user.phoneNumber,
          displayName: regex.isEmpty(user.displayName) ? "" : user.displayName,
          email: user.email,
          accessToken: user.accessToken,
          socialType: "google.com",
          profilePic: regex.isEmpty(user.photoURL) ? "" : user.photoURL,
        });
        getUser(uid);
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast(errorMessage);
      });
  };

  return (
    <div className="about-sec">
      <Container fluid>
        <div className="card-bg card-blue">
          <div className="formblock">
            <div style={{ textAlign: 'center' }}>
              <img src={loginicon} alt="icon img" />
              <h2>Create Account</h2>
              <p>Create account to use our services</p>
            </div>
            <Form>
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group> */}
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Confirm</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Your Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
              <Button className="clr-btn" onClick={handleSubmit}>
                Create Account
              </Button>
              <h6>
                Already have an account? Click<Link to={"/"}>Login</Link>
              </h6>
              {/* <span>
                <b>Or</b>
              </span> */}
              {/* <div className="google-login" onClick={loginWithGoogle}>
                <Link to={""}>
                  SIGNUP WITH GOOGLE
                  <FontAwesomeIcon icon="fa-google" />
                </Link>
              </div> */}
            </Form>
          </div>
        </div>
        <ToastContainer />
      </Container>
    </div>
  );
}

export default Register;
