import { Container } from 'react-bootstrap';
import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import loginicon from '../../../src/images/login-img.png';


function Faqs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='about-sec'>
      <Container fluid>
        <div className='card-bg card-blue'>
          <div className='formblock'>
            <div style={{ textAlign: 'center' }}>
              <img src={loginicon} alt="icon img" />
              <h2>FAQ</h2>
              <p>Frequently Asked Questions</p>
            </div>
            <Accordion className='acc-blk' defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>What kinds of questions can I ask?</Accordion.Header>
                <Accordion.Body>
                  You can ask PetroTech all sorts of questions about wells or pipelines in Texas. We add more functionality periodically. Accepted queries include:
                  Show me abandoned pipelines in Anderson county
                  Give me all of the wells producing deeper/shallower than 9000 feet in Marion county.
                  I want to see all permitted locations in Rusk county.
                  Give me all of the dry holes in Andrews county.
                  I'm looking for all of the operators in Panola county.
                  Show me all of the abandonded pipelines in Karnes county with wells displayed too.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How do I request additional data?</Accordion.Header>
                <Accordion.Body>
                  To request additional data, navigate to the "Data Request" page.
                  Here, you can fill out the form with your data request details and submit it.
                  We will review your request and get back to you as soon as possible.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>What types of data can I access through this platform?</Accordion.Header>
                <Accordion.Body>
                  Our platform provides comprehensive data on oil and gas wells.
                  This includes information like well locations, operators, depths, and more.
                  We are constantly working on expanding the types of data available.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>How can I download the data?</Accordion.Header>
                <Accordion.Body>
                  On the main page, below the map, there's a "Download Full Dataset" link.
                  Click on this link to download the entire dataset as a CSV file.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>How often is the data updated?</Accordion.Header>
                <Accordion.Body>
                  Our data is updated regularly to provide the most accurate and up-to-date information.
                  The specific frequency of updates can vary.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Who do I contact for technical support?</Accordion.Header>
                <Accordion.Body>
                  If you are experiencing technical difficulties or have questions about using our platform, please reach out to our support team at SUPPORT.
                  We're here to help!
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

      </Container>
    </div>

  );

}


export default Faqs;
