import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import PriceCard from '../../components/priceCard';
import CheckoutForm from '../../components/checkoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import axios from 'axios';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51MwxXRIOjo3FBYC1FTSycIkfT0HdNtS8YQwauQsLQp8AiN9er5T85KBpEwi00XUooiHmS8P6nD2aYrrYFhoQDAMh00iUHRSZHg');


function Pricing() {

  console.log('stripePromise', stripePromise)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState("");
  const setChange = (e) => {
    console.log('setChange')
    setShow(e.target.checked)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const setCheckoutResultMessage = () => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }


  const triggerCheckout = async () => {
    console.log('triggerCheckout')
    const uid = localStorage.user && JSON.parse(localStorage.user).uid;
    console.log('triggerCheckout - user', uid)

    try {
      const response = await axios.post("https://us-central1-rrc-mapping.cloudfunctions.net/stripe-checkout", { uid }, {


        headers: {
          "Content-Type": "application/json",
        },
      })

      console.log('response', response)
      if (response.data) {
        window.location.href = response.data.url;
        setMessage()
      }
    } catch (e) {
      console.log('error', e)
    }
  }


  return (
    <div className='about-sec'>
      <Container fluid>
        <div className='card-bg card-blue'>
          <div className='pric-blk'>
            <h2>Choose Your Plan and Enjoy Our Services</h2>
            <div className='tab-flx'>
              <h5>Monthly</h5>
              <div className='switch'>
                <input value={show} onChange={(e) => setChange(e)} checked={show.active} type="checkbox" id="checkbox1" />
                <label for="checkbox1"></label>
              </div>
              <h5>Yearly<span>Save $50/y</span></h5>
            </div>
            <PriceCard show={show} triggerCheckout={triggerCheckout} setMessage={setCheckoutResultMessage}></PriceCard>
          </div>

          {/* <Button onClick={triggerCheckout}> Checkout </Button> */}

        </div>

      </Container>

    </div>

  );

}


export default Pricing;
