import React, { useEffect } from 'react';

function Aboutus() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='about'>
      <h2>Aboutus </h2>
    </div>



  );

}


export default Aboutus;
