import React, { useState, useEffect } from "react";
import { regex } from "../../utils/regex";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../store/actions/types";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import loginicon from "../../../src/images/login-img.png";
import { getUserDetail } from "../../actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { loginWithEmailAndPassword } from "../../actions/socialLogin";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  GoogleAuthProvider, getAuth, signInWithPopup, sendPasswordResetEmail
} from "firebase/auth";


const auth = getAuth();
function Login({ showLoginContact }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent")
  }

  const handleSubmit = () => {
    if (!email) {
      toast("Email Required");
    } else if (!regex.validateEmail(email)) {
      toast("Invalid Email");
    } else if (!password) {
      toast("Passsword Required");
    } else {
      loginWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          toast("Signed In Succesfully");
          getUser(user.uid);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorMessage) {
            toast("Wrong Email/Password");
          }
        });
    }
  };

  const getUser = (id) => {
    getUserDetail(id)
      .then((response) => {
        console.log('getUserDetail - response', response)
        let userData = response.data;
        dispatch({
          type: LOGIN,
          payload: { userData },
        });
        console.log('getUser', userData)
        localStorage.setItem("user", JSON.stringify(userData));
        showLoginContact();
        navigate("/");
        window.location.reload(true);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const loginWithGoogle = () => {
    console.log('loginWithGoogle')
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log('loginWithGoogle - user', user)
        localStorage.setItem("user", JSON.stringify(user));
        getUser(user.uid);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        toast(errorMessage);
      });
  };

  return (
    <div className="about-sec-login">
      <Container fluid>
        <div className="card-bg card-blue vh">
          <div className="formblock">
            <div style={{ textAlign: 'center' }}>
              <img src={loginicon} alt="icon img" />
              <h2>Login</h2>
              <p>Login to your account for full access to PetroTech.AI features</p>
            </div>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ paddingLeft: '20px' }}>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              >
                <Form.Label style={{ paddingLeft: '20px' }}>Password</Form.Label>
                <Form.Control type="password" placeholder="Your Password" />
              </Form.Group>
              <Row>
                <Col md="6">
                  <Form.Check
                    inline
                    label="Remember me"
                    name="group1"
                    type="checkbox"
                    id="Remember"
                  />
                </Col>
                <Col md="6 text-right">
                  <Link to={"/forgot-password"}>
                    Forgot Password
                  </Link>
                </Col>
              </Row>
              <Button className="clr-btn" onClick={handleSubmit}>
                Sign In
              </Button>
              <h6>
                Don't have an account yet? Click
                <Link to={"/register"}>Create Account</Link>
              </h6>
              <span>
                <b>Or</b>
              </span>
              {/* <div className="google-login" onClick={loginWithGoogle}>
                <Link to={""}>
                  Sign In With Google
                  <FontAwesomeIcon icon="fa-brands fa-google" />
                </Link>
              </div> */}
            </Form>
          </div>
        </div>
        <ToastContainer />
      </Container>
    </div>
  );
}

export default Login;
